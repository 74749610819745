<template>
    <div class="wrapper">
        <img alt="IN logo" class="logo" src="../../assets/in_logo.svg">
        <transition name="slide">
            <second-screen
                :data_second="data_second"
                v-if="page_type === 2 && data_second.length != 0"
                screen="in"
            ></second-screen>
            <third-screen
                :data_second="data_second"
                :data_third="data_third"
                v-if="page_type === 3 && data_third.length != 0"
            ></third-screen>
        </transition>
    </div>
</template>

<script>
import SecondScreen from "@/components/screens/second";
import ThirdScreen from "@/components/screens/third";

export default {
    name: "itmonews",
    components: {ThirdScreen, SecondScreen},
    props: ['page_type', 'data_first', 'data_second', 'data_third']
}
</script>

<style scoped>


.logo {
    width: 60vw;
    height: 11.7vw;
}

.wrapper {
    background: transparent;
}
</style>