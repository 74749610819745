<template>
    <div class="highlights-wrapper">
        <second-card v-for="card in data_second" :data="card" :key="card._id"/>
        <third-card v-for="card in data_third" :data="card" :key="card._id"/>
    </div>
</template>

<script>

import SecondCard from '@/components/cards/second';
import ThirdCard from '@/components/cards/third';

export default {
    name: "third-screen",
    components: {
        ThirdCard,
        SecondCard
    },
    props: ['data_second', 'data_third']
}
</script>

<style scoped>
.highlights-wrapper {
    justify-content: flex-start;
}

.third-type {
    margin-top: 5%;
}

</style>