<template>
    <div :class="{'third-type': true, 'card': true, 'shadow': !data.transparent}" >
        <div class="left">
            <img :src="data.image"/>
        </div>
        <div class="right">
            <h1>{{ data.h1 }}</h1>
            <div class="bottom">
                <vue-qrcode
                    v-if="data.link != null"
                    :value="data.link"
                    class="qr-code"
                    :margin="0"
                    :color="{ dark: data.qr_color, light: '#00000000' }"
                ></vue-qrcode>
                <h3>{{data.h3}}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';

export default {
    name: "third-card",
    props: [
        'data'
    ],
    components: {
        VueQrcode,
    },
    methods: {
        getImage() {
            return this.data.image;
        }
    }
}
</script>

<style scoped>

.third-type {
    justify-content: space-between;
    height: 16%;
    background: white;
    flex-direction: row;
    width: 78%;
}

.left {
    border-radius: 50%;
    width: 12.672vh;
    background: white;
    height: 12.672vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 72px 12px rgba(0,0,0,0.08);
}
.right {
    width: calc(100% - 14vh);
    margin-left: 1.328vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.right .bottom {
    justify-content: flex-start;
}

.right .bottom h3 {
    margin-left: 1vh;
}

h1 {
    color: black;
    font-size: 2.2vh;
}

.qr-code {
    box-shadow: none;
}

h2 {
    margin: 0;
    font-size: 8vh;
    text-align: center;
}

h3 {
    color: #6D6D6D;
    font-size: 1.6vh;
}

.bottom {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

</style>