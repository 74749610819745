<template>
    <div class="highlights-wrapper">
        <first-card
        :data="data_first[0]">
        </first-card>
    </div>
</template>

<script>

import FirstCard from '@/components/cards/first';

export default {
    name: "first-screen",
    components: {
        FirstCard
    },
    props: ['data_first']
}
</script>

<style scoped>
</style>