<template>
    <div :class="{'second-type': true, 'card': true, 'shadow': !data.transparent}"  :style="{backgroundImage: 'url(\'' + getImage() + '\')'}">
        <vue-qrcode
            class="qr-code"
            :class="{'bottom-margin': data.h2 == null || data.h1 == null,
            'qr-background': screen != 'is'}"
            v-if="data.link != null"
            :value="data.link"
            :margin="0"
            :color="{ dark: data.qr_color, light: data.qr_light || '#00000000' }"
        ></vue-qrcode>
        <div class="bottom" v-if="data.h2 != null || data.h1 != null">
            <h2 v-if="data.h2 != null" :style="{'color': getH2Color()}">{{ data.h2 }}</h2>
            <h1 v-if="data.h1 != null">{{ data.h1 }}</h1>
            <h3 v-if="data.h3 != null & screen === 'mb'">{{ data.h3 }}</h3>
        </div>
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';

export default {
    name: "second-card",
    props: [
        'data', 'screen',
    ],
    components: {
        VueQrcode,
    },
    methods: {
        getH2Color() {
            if (this.screen == 'mb') {
                return "#FF1A47"
            } else if (this.screen == 'in') {
                return "#CE173D"
            }
            return "#6D6D6D"
        },
        getImage() {
            if (this.data.image != null) {
                return this.data.image;
            }
            return 'static/default.png'
        }
    }

}
</script>

<style scoped>


.second-type {
    height: 47.5%;
    padding: 0;
    width: 88%;
    justify-content: flex-end;
}

.qr-code {
    /*width: fit-content;*/
    align-self: flex-end;
    margin-right: 4%;
    margin-bottom: 2%;
}

.qr-background {
    box-shadow: 0 0 8px 8px rgba(0,0,0,0.15);
    background-color: rgba(0,0,0,0.15);
}

.bottom-margin {
    margin-bottom: 3%;
}

.bottom {
    z-index: 1;
    display: flex;
    padding: 4%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 92%;
    border-bottom-left-radius: 72px;
    border-bottom-right-radius: 72px;
    background: white;
}

h1 {
    color: black;
    font-size: 2.2vh;
}

h3 {
    margin-top: 1vh;
    font-size: 30px;
    color: rgba(0,0,0,0.6);
}
</style>