<template>
    <div id="app">
        <router-view
            :data_first="data_first"
            :data_second="data_second"
            :data_third="data_third"
            :page_type="page_type"
        />
    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            page_type: 1,
            elemnames: [],
            to_remove: [],
            data: {
                'first': [],
                'second': [],
                'third': []
            },
            data_watched: {
                'first': [],
                'second': [],
                'third': []
            },
            data_first: [],
            data_second: [],
            data_third: [],
            type: 'is',
            urls: {
                'is': 'https://api.itmostudents.ru/v1/screens',
                'mb': 'https://mbradio.ru/api/catalog/?limit=100&offset=0',
                'in': 'https://screens.itmostudents.ru/api/itmonews'
            }
        }
    },
    methods: {
        loadData: async function () {
            const axios = require('axios').default

            let self = this
            let timeoutTime = 0
            if (this.type == 'is') {
                let response = await axios.get(this.urls[this.type])
                this.updateData(response.data['data'])
                timeoutTime = 2 * 60 * 1000
            } else if (this.type == 'mb') {
                let response = await axios.post(this.urls[this.type], {
                    "topic": {
                        "study": true,
                        "science": true,
                        "daily": true,
                        "entertainment": true
                    },
                    "search": ""
                })
                let items = response.data['results']
                let data = []
                items.forEach(function (elem) {
                    if (elem['cover']) {
                        data.push({
                            'h1': elem['title'],
                            'h3': elem['description'],
                            'image': elem['cover'],
                            'link': 'https://mbradio.ru/publication/' + elem['id'],
                            'qr_color': '#ffffff',
                            // 'qr_light': '#ffffff',
                            'type': 2
                        })
                    }
                })
                self.updateData(data)
                timeoutTime = 10 * 60 * 1000
            } else if (this.type === 'in') {
                let response = await axios.get(this.urls[this.type])
                let items = response.data['list']
                let data = []
                items.forEach(function (elem) {
                    if (
                        'img' in elem &&
                        elem['img'] != null &&
                        'news_title' in elem &&
                        elem['news_title'].length < 100
                    ) {
                        data.push({
                            'h1': elem['news_title'],
                            'h2': elem['category_child'],
                            'image': elem['img'],
                            'link': elem['url_original'],
                            'qr_color': '#ffffff',
                            // 'qr_light': '#ffffff',
                            'type': 2
                        })
                    }
                })
                self.updateData(data)
                timeoutTime = 10 * 60 * 1000
            }
            setTimeout(function () {
                self.loadData()
            }, timeoutTime)
            return null
        },
        updateData(data) {
            let downloaded_elemnames = [],
                self = this;
            data.forEach(function (elem) {
                let elemname = elem['h1'] + elem['h2'] + elem['h3'] + elem['image'] + elem['link'];
                downloaded_elemnames.push(elemname)
                if (elem.type === 1) {
                    if (!self.elemnames.includes(elemname)) {
                        self.data['first'].push(elem)
                        self.elemnames.push(elemname)
                    }
                } else if (elem.type === 2) {
                    if (!self.elemnames.includes(elemname)) {
                        self.data['second'].push(elem)
                        self.elemnames.push(elemname)
                    }
                } else {
                    if (!self.elemnames.includes(elemname)) {
                        self.data['third'].push(elem)
                        self.elemnames.push(elemname)
                    }
                }
            })
            self.elemnames.forEach(function (elem) {
                if (!downloaded_elemnames.includes(elem) && !self.to_remove.includes(elem)) {
                    self.to_remove.push(elem)
                    let index = self.elemnames.indexOf(elem);
                    self.elemnames.splice(index, 1);
                }
            })
        },
        getFirstReady() {
            if (this.data['first'].length === 0) {
                return [false, 1]
            }
            let shown_now = this.data['first'].pop()
            this.data_first = [shown_now]
            this.data_watched['first'].push(shown_now)
            return [true, 1]
        },
        getSecondReady() {
            if (this.data['second'].length === 0) {
                return [false, 2]
            } else if (this.data['second'].length == 1) {
                if (this.data['third'].length > 0) {
                    return this.getThirdReady()
                } else {
                    let show_now_one = this.data['second'].pop();
                    this.data_second = [show_now_one]
                    this.data_watched['second'].push(show_now_one)
                    return [true, 2]
                }
            } else {
                let show_now_one = this.data['second'].pop();
                let show_now_two = this.data['second'].pop();
                this.data_second = [show_now_one, show_now_two]
                this.data_watched['second'].push(show_now_one)
                this.data_watched['second'].push(show_now_two)
                return [true, 2]
            }
        },
        getThirdReady() {
            if (this.data['third'].length === 0) {
                return [false, 3]
            } else {
                this.data_second = []
                this.data_third = []
                if (this.data['second'].length >= 1) {
                    let show_now_one = this.data['second'].pop();
                    let show_now_two = this.data['third'].pop();
                    let show_now_three = this.data['third'].pop();
                    this.data_second = [show_now_one]
                    if (show_now_two !== undefined) {
                        this.data_third = [show_now_two]
                        this.data_watched['third'].push(show_now_two)
                    }
                    if (show_now_three !== undefined) {
                        this.data_third.push(show_now_three)
                        this.data_watched['third'].push(show_now_three)
                    }
                    this.data_watched['second'].push(show_now_one)
                    return [true, 3]
                } else {
                    let show_now_one = this.data['third'].pop();
                    let show_now_two = this.data['third'].pop();
                    let show_now_three = this.data['third'].pop();
                    let show_now_four = this.data['third'].pop();
                    if (show_now_one !== undefined) {
                        this.data_third = [show_now_one]
                        this.data_watched['third'].push(show_now_one)
                    }
                    if (show_now_two !== undefined) {
                        this.data_third.push(show_now_two)
                        this.data_watched['third'].push(show_now_two)
                    }
                    if (show_now_three !== undefined) {
                        this.data_third.push(show_now_three)
                        this.data_watched['third'].push(show_now_three)
                    }
                    if (show_now_four !== undefined) {
                        this.data_third.push(show_now_four)
                        this.data_watched['third'].push(show_now_four)
                    }
                    return [true, 3]
                }
            }
        },
        switchToFirst() {
            this.data_second = []
            this.data_third = []
            this.page_type = 1
        },
        switchToSecond() {
            this.data_first = []
            this.data_third = []
            this.page_type = 2
        },
        switchToThird() {
            this.data_first = []
            this.page_type = 3
        },
        resetData() {
            let self = this
            this.data_watched['first'].forEach(function (elem) {
                let elemname = elem['h1'] + elem['h2'] + elem['h3'] + elem['image'] + elem['link'];
                let index = self.to_remove.indexOf(elemname);
                if (index > -1) {
                    self.to_remove.splice(index, 1);
                } else {
                    self.data['first'].push(elem)
                }
            })
            this.data_watched['second'].forEach(function (elem) {
                let elemname = elem['h1'] + elem['h2'] + elem['h3'] + elem['image'] + elem['link'];
                let index = self.to_remove.indexOf(elemname);
                if (index > -1) {
                    self.to_remove.splice(index, 1);
                } else {
                    self.data['second'].push(elem)
                }
            })
            this.data_watched['third'].forEach(function (elem) {
                let elemname = elem['h1'] + elem['h2'] + elem['h3'] + elem['image'] + elem['link'];
                let index = self.to_remove.indexOf(elemname);
                if (index > -1) {
                    self.to_remove.splice(index, 1);
                } else {
                    self.data['third'].push(elem)
                }
            })
            this.data_watched['first'] = [];
            this.data_watched['second'] = [];
            this.data_watched['third'] = [];
        },
        nextScreen() {
            if (this.page_type == 3) {
                let first = this.getFirstReady();
                if (first[0]) {
                    this.switchToFirst()
                } else {
                    let second = this.getSecondReady();
                    if (second[0]) {
                        if (second[1] == 2) {
                            this.switchToSecond()
                        } else {
                            this.switchToThird()
                        }
                    } else {
                        let third = this.getThirdReady();
                        if (third[0]) {
                            this.page_type = 0
                            let self = this
                            setTimeout(function () {
                                self.switchToThird()
                            }, 350)
                        } else {
                            this.resetData()
                            this.nextScreen()
                            return
                        }
                    }
                }
            } else if (this.page_type == 2) {
                let third = this.getThirdReady();
                if (third[0]) {
                    this.switchToThird()
                } else {
                    let first = this.getFirstReady();
                    if (first[0]) {
                        this.switchToFirst()
                    } else {
                        let second = this.getSecondReady();
                        if (second[0]) {
                            if (second[1] == 2) {
                                this.page_type = 0
                                let self = this
                                setTimeout(function () {
                                    self.switchToSecond()
                                }, 350)
                            } else {
                                this.switchToThird()
                            }
                        } else {
                            this.resetData()
                            this.nextScreen()
                            return
                        }
                    }
                }
            } else if (this.page_type == 1) {
                let second = this.getSecondReady();
                if (second[0]) {
                    if (second[1] == 2) {
                        this.switchToSecond()
                    } else {
                        this.switchToThird()
                    }
                } else {
                    let third = this.getThirdReady();
                    if (third[0]) {
                        this.switchToThird()
                    } else {
                        let first = this.getFirstReady();
                        if (first[0]) {
                            this.page_type = 0
                            let self = this
                            setTimeout(function () {
                                self.switchToFirst()
                            }, 350)
                        } else {
                            this.resetData()
                            this.nextScreen()
                            return
                        }
                    }
                }
            }
            let self = this;
            setTimeout(function () {
                self.nextScreen()
            }, 15000)
        }
    },
    watch: {
        $route() {
            window.location.reload()
        }
    },
    created: async function () {
        if (this.$route.path == '/is') {
            this.type = 'is'
        } else if (this.$route.path == '/mb') {
            this.type = 'mb'
        } else if (this.$route.path == '/in') {
            this.type = 'in'
        } else {
            this.$router.push('/is')
        }
        await this.loadData()
        this.nextScreen()
    }
}
</script>

<style>
html, body, #app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

div {
    overflow: hidden;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100vw;
    height: 100vh;
}

.wrapper {
    /*padding: 5vh 6vw;*/
    display: flex;
    /*width: 88vw;*/
    /*height: 90vh;*/
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

.logo {
    margin-top: 5vh;
    margin-left: 6vw;
}

.highlights-wrapper {
}

.card {
    border-radius: 72px;
    display: flex;
    flex-direction: column;
    padding: 5%;
    width: 90%;
    margin-left: 6vw;
    background-size: cover;
    background-position: center center;
}

.shadow {
    box-shadow: 0px 0px 72px 12px rgba(0, 0, 0, 0.16);
}

.highlights-wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    /*padding: 5vh 0;*/
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: 80vh;
    position: absolute;
    bottom: 0;
    overflow: hidden;
}

.slide-enter-active {
    animation: backInRight 0.7s;
}

.slide-leave-active {
    animation: backOutLeft 0.7s;
}

.qr-code {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
    width: 13%;
}

@keyframes backInRight {
    0% {
        -webkit-transform: translateX(2000px) scale(.8);
        transform: translateX(2000px) scale(.8);
        opacity: .7
    }

    80% {
        -webkit-transform: translateX(0) scale(.8);
        transform: translateX(0) scale(.8);
        opacity: .7
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes backOutLeft {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    20% {
        -webkit-transform: translateX(0) scale(.8);
        transform: translateX(0) scale(.8);
        opacity: .7
    }

    to {
        -webkit-transform: translateX(-2000px) scale(.8);
        transform: translateX(-2000px) scale(.8);
        opacity: .7
    }
}

h1 {
    font-size: 4vh;
    font-family: 'SFProDisplay-Bold', serif;
    font-weight: normal;
    text-align: left;
    margin: 0;
    color: white;
}

h2 {
    font-size: 1.6vh;
    font-family: 'SFProDisplay-Semibold', serif;
    font-weight: normal;
    text-align: left;
    margin: 0;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 1vh;
    text-transform: uppercase;
}

h3 {
    font-size: 2.2vh;
    color: white;
    font-family: 'SFProDisplay-Regular', serif;
    font-weight: normal;
    text-align: left;
    margin: 0;
}
</style>
