<template>
    <div class="wrapper">
        <img alt="IS logo" class="logo" src="../../assets/is_logo.png">
        <transition name="slide">
            <first-screen
                :data_first="data_first"
                v-if="page_type === 1 && data_first.length != 0"
            ></first-screen>
            <second-screen
                :data_second="data_second"
                v-if="page_type === 2 && data_second.length != 0"
                screen="is"
            ></second-screen>
            <third-screen
                :data_second="data_second"
                :data_third="data_third"
                v-if="page_type === 3 && data_third.length != 0"
            ></third-screen>
        </transition>
    </div>
</template>

<script>
import FirstScreen from "@/components/screens/first";
import SecondScreen from "@/components/screens/second";
import ThirdScreen from "@/components/screens/third";

export default {
    name: "itmostudents",
    components: {ThirdScreen, SecondScreen, FirstScreen},
    props: ['page_type', 'data_first', 'data_second', 'data_third']
}
</script>

<style scoped>

.logo {
    width: 43vw;
    height: 12vw;
}

</style>