import Vue from 'vue'
import App from './App.vue'

import Router from 'vue-router'
import itmostudents from "@/components/views/itmostudents";
import megabyte from "@/components/views/megabyte";
import itmonews from "@/components/views/itmonews";

Vue.config.productionTip = false

Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: '/is',
      component: itmostudents
    },
    {
      path: '/mb',
      component: megabyte
    },
    {
      path: '/in',
      component: itmonews
    },
  ]
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
