<template>
    <div class="highlights-wrapper">
        <second-card
            v-for="card in data_second"
            :data="card"
            :key="card._id"
            :screen="screen"
        />
    </div>
</template>

<script>

import SecondCard from '@/components/cards/second';

export default {
    name: "second-screen",
    components: {
        SecondCard
    },
    props: ['data_second', 'screen'],
}
</script>

<style scoped>
.highlights-wrapper {
    justify-content: space-between;
}

</style>