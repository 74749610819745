<template>
    <div :class="{'first-type': true, 'card': true, 'shadow': !data.transparent}" :style="{backgroundImage: 'url(\'' + getImage() + '\')'}">
        <div class="top" v-if="data.h2 != null || data.h1 != null">
            <h2>{{ data.h2 }}</h2>
            <h1>{{ data.h1 }}</h1>
        </div>
        <div class="bottom" v-if="data.h3 != null || data.link != null">
            <h3>{{ data.h3 }}</h3>
            <vue-qrcode
                v-if="data.link != null"
                :value="data.link"
                :margin="0"
                :color="{ dark: data.qr_color, light: '#00000000'}"
            ></vue-qrcode>
        </div>
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';

export default {
    name: "first-card",
    props: [
        'data'
    ],
    components: {
        VueQrcode,
    },
    methods: {
        getImage() {
            if (this.data.image != null) {
                return this.data.image;
            } else {
                return 'static/default.png'
            }
        }
    }
}
</script>

<style scoped>

.first-type {
    justify-content: space-between;
    height: 100%;
    padding: 0;
    position: relative;
    width: 88%;
}

.top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
    width: 90%;
    padding: 5%;
    border-top-left-radius: 72px;
    border-top-right-radius: 72px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
}

.bottom {
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
    border-bottom-left-radius: 72px;
    border-bottom-right-radius: 72px;
    display: flex;
    padding: 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    position: absolute;
    bottom: 0;
}


</style>