<template>
    <div class="wrapper">
        <div class="background-line"/>
        <img alt="MB logo" class="logo" src="../../assets/mb_logo.svg">
        <transition name="slide">
            <second-screen
                :data_second="data_second"
                v-if="page_type === 2 && data_second.length != 0"
                screen="mb"
            ></second-screen>
        </transition>
    </div>
</template>

<script>
import SecondScreen from "@/components/screens/second";

export default {
    name: "megabyte",
    components: {SecondScreen},
    props: ['page_type', 'data_first', 'data_second', 'data_third']
}
</script>

<style scoped>
.background-line {
    position: fixed;
    height: 100vh;
    width: 12vw;
    background: #FF1A47;
    z-index: -1;
}

.logo {
    width: 60vw;
    height: 11.7vw;
}

.wrapper {
    background: transparent;
}

</style>